import BaseCom from "./BaseCom";
import GestureElement from "./GestureElement";
import L from "./Lang";
import Icon from "./Icon";
import Globals from "../shared/Globals";
import { PrintGlobal } from "./PrintView";
import { Link } from "react-router-dom";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { qreq } from "../shared/qrequest";
import AppFormActions from "./AppFormActions";
import Alert from "./Alert";

class CustomerOrderHistory extends BaseCom {
    async componentDidMount() {
        await this.load();
    }

    load = async () => {
        try {
            let j = await qreq.getAsync('/api/order/history/list?customerID=' + this.props.params.id
                + '&onlyInvoice=true'
                + '&onlySigned=true'
            );
            if(j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }
        catch(e) {
            this.unkownErrorCallback(e);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <AppFormActions showBackButton />
                {this.state.list ? this.state.list.length === 0 ? <><Alert><L>There are no orders.</L></Alert></>
                        : <div>
                            <p className="text-muted"><L>There</L> {this.state.list.length === 1 ? <L>is</L> : <L>are</L>} {this.state.list.length} {this.state.list.length === 1 ? <L>invoice.</L> : <L>invoices.</L>}</p>
                            <div className="list-group">
                            {this.state.list.map(i => <GestureElement key={i.id} className="list-group-item d-flex align-items-center"
                                onSwipeRight={() => { i.active = true; this.setState({ list: [...this.state.list] }); }}
                                onSwipeLeft={() => { i.active = false; this.setState({ list: [...this.state.list] }); }}
                            >
                                <Link to={i.saleType === 2 ? '/console/visit/' + i.routePlanID + '/order/pre/' + i.id : '/console/visit/' + i.routePlanID + '/order/pre/' + i.id} className="d-block flex-fill">
                                    <div>{i.orderIdent} {i.isSigned && <Icon icon="faSignature" className="text-success" />}</div>
                                    <div className="text-muted small"><L>Deliver On:</L> {Globals.toDateDeltaDisplay(i.deliver_on)}</div>
                                    {this.state.user?.isSupervisor && <div className="small text-super"><L>Salesman:</L> {i.createdByUserName}</div>}
                                </Link>
                                <div className={i.active ? 'd-flex gap-1' : 'd-none d-md-flex gap-1'}>
                                    <button type="button" className="btn btn-lg btn-primary" onClick={() => PrintGlobal.show(Globals.serverUrl + '/api/receipt/sale/html/' + i.saleType + '/' + (i.saleType === 2 ? i.invoiceID : i.id) + '/' + i.user_num + '-' + i.ord_id + '-' + i.ord_seq + '.html')}><Icon icon="faReceipt" /></button>
                                    <button type="button" className="btn btn-primary btn-lg" onClick={() => window.open(Globals.serverUrl + '/api/report/purchase-order/pdf/' + i.saleType + '/' + (i.saleType === 2 ? i.invoiceID : i.id) + '/' + i.user_num + '-' + i.ord_id + '-' + i.ord_seq + '.pdf')}><Icon icon="faFilePdf" /></button>
                                    <button type="button" className="btn btn-danger btn-lg d-none" onClick={() => this.delete(i)}><Icon icon="faTrash" /></button>
                                </div>
                            </GestureElement>)}
                        </div>
                        </div> : <LoadingAlert />}
            </div>
        )
    }
}

export default withParamsAndNavigate(CustomerOrderHistory);